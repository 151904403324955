<template>
  <div style="padding: 20px">
    <el-form style="width: 500px" label-width="100px" label-position="left">
      <el-form-item label="权限名称">
        <el-input v-model="form.acitonName" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="权限编码">
        <el-input v-model="form.code" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="图标">
        <el-input v-model="form.ico" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="父权限ID">
        <el-input v-model="form.parntId" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="form.sort" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="路径地址">
        <el-input v-model="form.urlAddress" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="Submit">提交</el-button>
        <el-button type="success" @click="Query">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        // acitonName: "", // 权限名称
        // code: "", //权限编码
        // ico: "", // 图标
        // parntId: "", //父权限ID
        // sort: "", //排序
        // status: "1", // 状态 1.启用 2.禁用
        // urlAddress: "", //路径地址
      },
    };
  },
  mounted() {
    this.Reset();
  },
  methods: {
    //   新增
    Submit() {
      this.axios
        .post("/admin/action/addAction", this.form)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.mesg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.mesg,
              type: "error",
            });
          }
          this.Reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询
    Query() {
      this.axios.get("/admin/action/selectActionList2", {}).then((res) => {
        console.log(res.data.data);
      });
    },
    Reset() {
      this.form = {
        acitonName: "", // 权限名称
        code: "", //权限编码
        ico: "", // 图标
        parntId: "", //父权限ID
        sort: "", //排序
        status: "1", // 状态 1.启用 2.禁用
        urlAddress: "", //路径地址
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
